import React from "react";

function QuranCodes() {
  return (
    <body className="Centre">
      <div className="circle">
        <h1 className="main_title">Quran Codes</h1>
        {/* <h2>51</h2> */}
      </div>
    </body>
  );
}

export default QuranCodes;
